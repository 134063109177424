import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Preloader = ({ fullscreen }: { fullscreen?: boolean }) => {
  return (
    <Spin
      style={{
        width: fullscreen ? '100vw' : '100%',
        height: fullscreen ? '100vh' : '100%',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    />
  );
};

export default Preloader;
