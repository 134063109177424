import { Form, Formik } from 'formik';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { FormGroup } from 'components/CustomFields';
import Icons from 'shared/ui/icons';

import resetPassword from './controller';
import css from './index.module.scss';

const ResetPassword = () => {
  const history = useHistory();
  return (
    <div className={cn(css.form, 'box')}>
      <div className={cn(css.header, 'flex flex-center mb3')}>
        {Icons.logo}
        <div className={css.title}>CRM</div>
      </div>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async (values) => {
          if (await resetPassword(values.email)) {
            history.replace('/');
          }
        }}
      >
        <Form>
          <FormGroup required name="email" label="Почта" />
          <Button htmlType="submit">Сбросить</Button>
        </Form>
      </Formik>
    </div>
  );
};

export default ResetPassword;
