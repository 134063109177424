import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useMemo } from 'react';

import Icons from 'shared/ui/icons';
import operatorModel from 'entities/operator';
import Preloader from 'shared/ui/preloader';
import { useAllowedRoutes } from 'app/routes';

import Logo from './assets/logo';
import css from './index.module.scss';

const Sidebar = () => {
  const operatorQ = operatorModel.queries.useCurrent();
  const operator = operatorQ.data;

  const logout = operatorModel.mutations.useLogout();

  const allowedRoutes = useAllowedRoutes();
  const [normal, footer] = useMemo(() => {
    const sidebar = allowedRoutes.filter((route) => route.sidebar);
    const normal = sidebar.filter((route) => !route.sidebarFooter);
    const footer = sidebar.filter((route) => route.sidebarFooter);
    return [normal, footer];
  }, [allowedRoutes]);

  if (operatorQ.isLoading) {
    return <Preloader />;
  }

  if (!operator) return null;

  const avatarLetters = `${operator.firstname?.charAt(0) ?? ''}${
    operator.lastname?.charAt(0) ?? ''
  }`;

  return (
    <div className={css.sidebar}>
      <div className={cn(css.sidebarLogo, 'flex mb4')}>
        <Logo />
      </div>
      <div className={css.sidebarMenu}>
        {normal.map((route) => (
          <div key={route.path}>
            <NavLink to={route.path}>{route.sidebar}</NavLink>
          </div>
        ))}
      </div>

      <div className={css.sidebarFooter}>
        <div className={css.sidebarMenu}>
          {footer.map((route) => (
            <div key={route.path}>
              <NavLink to={route.path}>{route.sidebar}</NavLink>
            </div>
          ))}
        </div>
        <div className={cn(css.sidebarOperator, 'flex flex-center')}>
          <div className={cn(css.sidebarOperatorAvatar, 'mr2')}>
            {avatarLetters}
          </div>
          <div className={css.sidebarOperatorFullname}>
            {operator.firstname ?? ''}
            <br /> {operator.lastname ?? ''}
          </div>
          <div
            className={cn(css.sidebarOperatorExit, 'mla')}
            role="button"
            tabIndex={0}
            onClick={() => logout.mutate()}
          >
            {Icons.exit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
