import create from 'zustand';

import { toggleInSet } from 'shared/helpers';
import type { ReportData, ReportType, ReportsEntry } from 'entities/report';

interface ReportsStore {
  reports: Partial<Record<ReportType, ReportData>>;
  initReports: (reports: Record<ReportType, ReportData>) => void;
  setReport: (type: ReportType, data: ReportData) => void;
  updateReport: (type: ReportType, data: Partial<ReportData>) => void;
  fieldsNotNeeded: Set<keyof ReportsEntry>;
  toggleField: (field: keyof ReportsEntry, state?: boolean) => void;
  reportTypes: Set<ReportType>;
  toggleReportType: (type: ReportType, state?: boolean) => void;
  resetReportTypes: () => void;
}

const useReportsStore = create<ReportsStore>((set) => ({
  reports: {},
  initReports: (reports) => set({ reports }),
  setReport: (type, data) =>
    set(({ reports }) => ({ reports: { ...reports, [type]: data } })),
  updateReport: (type, data) =>
    set(({ reports }) => ({
      reports: { ...reports, [type]: { ...reports[type], ...data } },
    })),
  fieldsNotNeeded: new Set<keyof ReportsEntry>(),
  toggleField: (field, state) =>
    set(({ fieldsNotNeeded: prevSet }) => ({
      fieldsNotNeeded: toggleInSet(prevSet, field, state),
    })),
  reportTypes: new Set<ReportType>(),
  toggleReportType: (reportType, state) =>
    set(({ reportTypes: prevSet }) => ({
      reportTypes: toggleInSet(prevSet, reportType, state),
    })),
  resetReportTypes: () => set({ reportTypes: new Set() }),
}));

export default useReportsStore;
