import { useMemo } from 'react';

import type { Operator, OperatorNonSuperRole, OperatorRole } from './types';
import { useCurrent } from './queries';

export const check = (
  operatorRoles: OperatorRole[],
  roles: OperatorNonSuperRole[],
) => {
  const availableRoles: OperatorRole[] = ['super_admin', ...roles];
  return operatorRoles.some((role) => availableRoles.includes(role));
};

export const useCheck = (...roles: OperatorNonSuperRole[]) => {
  const operatorRolesQ = useCurrent({
    select: (o) => o.roles,
  });
  const operatorRoles = operatorRolesQ.data!;

  return useMemo(
    () => !!operatorRoles && check(operatorRoles, roles),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [operatorRoles, ...roles],
  );
};

export const useCustom = (
  cb: (operator: Operator) => boolean,
  deps: unknown[] = [],
) => {
  const operatorQ = useCurrent();
  const operator = operatorQ.data!;
  return useMemo(
    () => cb(operator),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [operator, ...deps],
  );
};
