import type { AxiosRequestConfig } from 'axios';
import axios, { isAxiosError } from 'axios';
import Cookies from 'js-cookie';

// исключение, ибо нам надо чистить куки нашего оператора на 401
// eslint-disable-next-line boundaries/element-types
import { onLogout } from 'entities/operator/controller';

import type { ApiError } from './types';

export const baseURL = import.meta.env.REACT_APP_API_URL;
const api = axios.create({ baseURL });

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('operator_token');
    if (token) {
      const authConfig = { ...config, headers: config.headers ?? {} };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      authConfig.headers.Authorization = `Bearer ${token}`;
      return authConfig;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

//  401
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!isAxiosError(error)) {
      return Promise.reject(error);
    }
    if (error.response && error.response.status === 401) {
      onLogout();
    } else if (
      error.response &&
      error.response.status === 429 &&
      !error.response.headers?.['ratelimit-limit']
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const { baseURL: _, ...config } = error.config as AxiosRequestConfig<{
        baseURL?: string;
      }>; // it double appends baseURL
      return axios.request(config);
    }

    return Promise.reject(error);
  },
);

export default api;

export const isApiError = (error: unknown): error is ApiError => {
  if (!isAxiosError(error)) return false;
  const err = error as ApiError;
  if (typeof err.response?.data === 'string') return true;

  const message = err.response?.data?.message;
  const data = err.response?.data?.message;
  if (message !== null && typeof message !== 'string') return false;
  if (data && typeof data !== 'object') return false;
  return true;
};
