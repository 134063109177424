const Logo = () => (
  <svg
    width="73"
    height="19"
    viewBox="0 0 934 229"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M180.522 167.964L147.318 134.731L180.551 101.498H85.8394V196.18L119.102 162.947L152.306 196.18L180.522 167.964Z"
      fill="black"
    />
    <path
      d="M39.903 156.277V39.903H140.618V76.7321H180.521V0H0V196.18H85.8379V156.277H39.903Z"
      fill="#F7CE17"
    />
    <path
      d="M419.53 15.3618H382.157L337.775 88.0073L293.394 15.3618H256.02V178.873H293.394V83.8027L335.673 153.178H339.877L382.157 83.8027V178.873H419.53V15.3618Z"
      fill="black"
    />
    <path
      d="M520.046 62.0792L498.089 133.557L472.16 62.0792H433.619L480.102 173.5C474.963 187.749 467.255 192.888 450.904 192.888V225.59C483.606 227.225 504.395 212.509 517.476 175.369L557.42 62.0792H520.046Z"
      fill="black"
    />
    <path
      d="M733.374 87.7737H651.385V120.476H695.066C689.226 136.126 674.978 145.703 653.254 145.703C621.486 145.703 601.865 125.381 601.865 97.5844C601.865 68.8532 622.421 48.5311 649.984 48.5311C668.437 48.5311 683.153 56.9403 689.927 67.6853L721.695 49.4655C707.913 27.5084 681.518 12.0916 650.217 12.0916C602.099 12.0916 564.491 49.9327 564.491 97.3508C564.491 144.302 601.398 182.143 652.787 182.143C698.57 182.143 733.374 151.776 733.374 101.789V87.7737Z"
      fill="black"
    />
    <path
      d="M770.796 51.8014C782.241 51.8014 791.818 42.2243 791.818 30.7786C791.818 19.3328 782.241 9.75575 770.796 9.75575C759.35 9.75575 749.773 19.3328 749.773 30.7786C749.773 42.2243 759.35 51.8014 770.796 51.8014ZM753.277 178.873H788.315V62.0792H753.277V178.873Z"
      fill="black"
    />
    <path
      d="M899.681 62.0792V73.9921C891.972 64.415 880.76 58.809 865.577 58.809C832.408 58.809 808.115 85.905 808.115 118.14C808.115 150.375 832.408 177.471 865.577 177.471C880.76 177.471 891.972 171.865 899.681 162.288V173.033C899.681 189.618 889.636 198.027 873.285 198.027C857.869 198.027 851.095 191.486 846.656 183.311L816.757 200.596C827.502 219.75 847.824 228.86 872.117 228.86C903.184 228.86 933.784 212.275 933.784 173.033V62.0792H899.681ZM871.417 145.236C854.832 145.236 843.153 134.257 843.153 118.14C843.153 102.022 854.832 91.0439 871.417 91.0439C888.001 91.0439 899.681 102.022 899.681 118.14C899.681 134.257 888.001 145.236 871.417 145.236Z"
      fill="black"
    />
  </svg>
);

export default Logo;
