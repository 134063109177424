import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ApiError } from 'shared/api';
import api from 'shared/api';
import type { ObjectId } from 'shared/types';

import { getToken } from './controller';
import type { Operator, OperatorsDictParams, OperatorsDictRes } from './types';

const getOperatorsDictionary = (params: OperatorsDictParams) =>
  api
    .get<OperatorsDictRes>('/dictionaries/operators', {
      params,
    })
    .then(({ data }) => data);

type OperatorsQOptions<Data = OperatorsDictRes> = Omit<
  UseQueryOptions<OperatorsDictRes, ApiError, Data>,
  'queryKey' | 'queryFn'
>;

export const useOperatorsCustom = <Data = OperatorsDictRes>(
  config: OperatorsDictParams = {},
  options?: OperatorsQOptions<Data>,
) =>
  useQuery<OperatorsDictRes, ApiError, Data>({
    queryKey: ['dictionaries', 'operators', config],
    queryFn: () => getOperatorsDictionary(config),
    ...options,
  });

const selectDict = (data: OperatorsDictRes) =>
  data.reduce<Record<ObjectId, string>>((acc, item) => {
    acc[item._id] = `${item.firstname} ${item.lastname}`;
    return acc;
  }, {});

export const useOperatorsDict = <Data = Record<ObjectId, string>>(
  config: OperatorsDictParams = {},
  options?: OperatorsQOptions<Data>,
) =>
  useOperatorsCustom(config, {
    select: selectDict as OperatorsQOptions<Data>['select'],
    ...options,
  });

export const useOperators = <Data = Record<ObjectId, string>>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsDict({}, options);
export const useCoordinators = <Data = Record<ObjectId, string>>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsDict({ roles: ['coordinator'] }, options);
export const useRecruiters = <Data = Record<ObjectId, string>>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsDict({ roles: ['recruiter'] }, options);
export const useManagers = <Data = Record<ObjectId, string>>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsDict({ onlyManagers: true }, options);

const selectEmails = (data: OperatorsDictRes) =>
  data.reduce<string[]>((acc, item) => {
    acc.push(item.email);
    return acc;
  }, []);

export const useEmails = <Data = string[]>(options?: OperatorsQOptions<Data>) =>
  useOperatorsCustom(
    {},
    { select: selectEmails as OperatorsQOptions<Data>['select'], ...options },
  );

export const useOperator = <Data = Operator>(
  id?: ObjectId,
  options?: Omit<
    UseQueryOptions<Operator, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<Operator, ApiError, Data>({
    queryKey: ['operators', id],
    queryFn: async () => {
      return api.get<Operator>(`/operators/${id}`).then(({ data }) => data);
    },
    enabled: !!id && options?.enabled !== false,
    ...options,
  });

export const useCurrent = <Data = Operator>(
  options?: Omit<
    UseQueryOptions<Operator, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<Operator, ApiError, Data>({
    queryKey: ['profile'],
    queryFn: async () => {
      if (!getToken()) {
        throw new Error('Token not found');
      }
      return api.get<Operator>('/operator/profile').then(({ data }) => data);
    },
    ...options,
  });
};
