import * as mutations from './mutations';
import * as queries from './queries';

const receiptModel = {
  mutations,
  queries,
};

export default receiptModel;

export * from './types';
