import type { Context, Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';
import type { TablePaginationConfig, TableProps } from 'antd';
import _pick from 'lodash/pick';

import type { QueryResult } from 'shared/api';
export interface BaseTableContextType<T, F, D = T[]> {
  data: T[];
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  appliedFilters: F;
  applyFilters: (filters: F) => Promise<void>;
  initialFiltersLoaded: boolean;
  sort?: string;
  endpointQ: QueryResult<D>;
  total?: number;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  onShowSizeChange: TablePaginationConfig['onShowSizeChange'];
  handleTableChange: TableProps<T>['onChange'];
}

const BaseTableContext = createContext<
  BaseTableContextType<unknown, unknown, unknown>
>({} as BaseTableContextType<unknown, unknown, unknown>) as Context<
  BaseTableContextType<unknown, unknown, unknown>
> & {
  use: typeof useBaseTable;
};

export const useBaseTable = <T, F = unknown, D = T[]>() =>
  useContext<BaseTableContextType<T, F, D>>(
    BaseTableContext as Context<BaseTableContextType<T, F, D>>,
  );

BaseTableContext.use = useBaseTable;

export default BaseTableContext;
