import create from 'zustand';

import type { ObjectId } from 'shared/types';

import type { OperatorRole } from './types';

export interface CurrentOperatorState {
  _id: ObjectId;
  firstname: string;
  lastname: string;
  roles: OperatorRole[];
  phone: number;
}

const useCurrentOperatorStore = create<{
  operator?: CurrentOperatorState;
  setOperator: (operator?: CurrentOperatorState) => void;
}>((set) => ({
  setOperator: (operator) => set({ operator }),
}));

export const useCurrent = () => useCurrentOperatorStore((s) => s.operator);

export default useCurrentOperatorStore;
