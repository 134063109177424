import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import { Field, Form, Formik } from 'formik';
import cn from 'classnames';

import Icons from 'shared/ui/icons';
import operatorModel from 'entities/operator';

import css from './index.module.scss';

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const login = operatorModel.mutations.useLogin({
    onError: (err) => {
      const errorData = err.response?.data;
      const errorText =
        typeof errorData === 'string' ? errorData : errorData?.message;
      const text =
        errorText ?? JSON.stringify(errorText) ?? 'Сервер обновляется';
      void message.error(text);
    },
  });

  return (
    <Formik<LoginFormValues>
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values) => {
        await login.mutateAsync(values);
      }}
    >
      {(form) => (
        <Form className={cn(css.loginForm, 'box')}>
          <div className={cn(css.loginFormHeader, 'flex flex-center mb3')}>
            {Icons.logo}
            <div className={css.loginFormTitle}>CRM</div>
          </div>
          <div className="form-group">
            <Field
              name="email"
              required
              placeholder="Email"
              className="ant-input"
            />
          </div>
          <div className="form-group">
            <Field
              name="password"
              type="password"
              required
              placeholder="Пароль"
              className="ant-input"
            />
            <Link to="/reset-password">Забыли пароль?</Link>
          </div>
          <Button type="primary" loading={form.isSubmitting} htmlType="submit">
            Войти
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
