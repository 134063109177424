import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/ru';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import ruRU from 'antd/lib/locale/ru_RU';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import operatorModel from 'entities/operator';
import Preloader from 'shared/ui/preloader';
import { LayoutContext } from 'shared/contexts';
import { initMainSocket } from 'features/socket';
import { queryClient } from 'shared/query';

import history from './history';
import { unauthorizedRoutes, useAllowedRoutes } from './routes';
import Sidebar from './sidebar';
import updateSW from './service-worker';

import './styles/index.scss';

moment.updateLocale('ru', {
  weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthsShort: [
    'янв.',
    'фев.',
    'мар.',
    'апр.',
    'мая',
    'июн.',
    'июл.',
    'авг.',
    'сен.',
    'окт.',
    'ноя.',
    'дек.',
  ],
  week: {
    dow: 1,
    doy: 4,
  },
});

momentTZ.locale('ru');
momentTZ.tz.setDefault('Europe/Moscow');

void updateSW();

const Layout = () => {
  const operatorQ = operatorModel.queries.useCurrent();
  useEffect(() => {
    if (operatorQ.isSuccess) {
      initMainSocket();
    }
  }, [operatorQ.isSuccess]);

  const [layoutRef, setLayoutRef] = useState<HTMLDivElement | null>(null);
  const ref = useCallback((node: HTMLDivElement) => {
    if (node) {
      setLayoutRef(node);
    }
  }, []);

  const allowedRoutes = useAllowedRoutes();

  if (operatorQ.isLoading) {
    return <Preloader fullscreen />;
  }

  if (!operatorQ.data) {
    return (
      <Switch>
        {unauthorizedRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Switch>
    );
  }

  return (
    <>
      <Sidebar />
      <Suspense fallback={<Preloader fullscreen />}>
        <div className="layout" ref={ref}>
          <LayoutContext.Provider value={layoutRef}>
            <Switch>
              {allowedRoutes.map((route) => (
                <Route key={route.path} {...route} exact />
              ))}
            </Switch>
          </LayoutContext.Provider>
        </div>
      </Suspense>
    </>
  );
};

const App = () => (
  <ConfigProvider locale={ruRU}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Router history={history}>
          <Layout />
        </Router>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ConfigProvider>
);

export default App;
