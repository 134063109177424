import type { OperatorRole } from './types';

export const TOKEN_COOKIE = 'operator_token';

export const OPERATOR_ROLES: Record<OperatorRole, string> = {
  content: 'контент',
  customer_service: 'клиентский сервис',
  coordinator: 'координатор',
  super_admin: 'супер админ',
  recruiter: 'рекрутер',
  operator: 'оператор',
  manager: 'менеджер',
  admin: 'админ',
  payment_moderation_admin: 'модератор платежей (админ)',
  payment_moderation_bonus: 'модератор платежей (бонус)',
  payment_moderation_monthly_salary: 'модератор платежей (ЗП)',
  financial_manager: 'финансовый менеджер',
  heatmap_operator: 'оператор тепловой карты',
};
