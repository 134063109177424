import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // invalidate after one hour
      staleTime: 60 * 60 * 1000,
      // do not retry (definition of insanity)
      retry: false,
    },
  },
});

export default queryClient;
