import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import create from 'zustand';
import TabElect from 'tab-elect';

import type { ObjectId } from 'shared/types';
import type { ImportStateResponse } from 'entities/import';
import type { ReportData, ReportType } from 'entities/report';
// TODO: избавиться, когда вынесем сокет для reports в отдельный неймспейс
// eslint-disable-next-line boundaries/element-types
import useReportsStore from 'pages/reports/store';
import { baseURL } from 'shared/api';
import type { ReceiptGenerationStatus } from 'entities/receipt';
import { queryClient } from 'shared/query';
import receiptModel from 'entities/receipt';

type SocketNs = 'import';

export interface ExportState {
  link?: string | null;
  status: 'new' | 'ready' | 'error';
  type: 'template' | 'workplace';
}

type MainSocket = Socket<{
  call: (
    payload:
      | { workerId: ObjectId; phone?: never }
      | { phone: number; workerId?: never },
  ) => void;
  reports: (payload: { type: ReportType } & ReportData) => void;
  'payments/report': (payload: {
    fileName: string;
    status: 'ready' | 'error' | 'pending' | 'new';
  }) => void;
  connect_error: (payload: Error) => void;
  'receipts/status-update': (payload: {
    status: ReceiptGenerationStatus;
    entityId: ObjectId;
  }) => void;
  'export/status': (payload: ExportState) => void;
}>;

type ImportSocket = Socket<{
  import: (payload: ImportStateResponse) => void;
}>;

type NamespaceSocket<Ns extends SocketNs | undefined> =
  Ns extends 'import' ? ImportSocket : MainSocket;

interface SocketStore extends Record<SocketNs, Socket | null> {
  main: MainSocket | null;
  import: ImportSocket | null;
  init: <Ns extends SocketNs | undefined = undefined>(
    ns?: Ns,
  ) => NamespaceSocket<Ns>;
}

const useSocket = create<SocketStore>((set, get) => ({
  main: null,
  import: null,
  init: (namespace?: SocketNs) => {
    // close previous socket if exists
    if (namespace) {
      get()[namespace]?.close();
    } else {
      get().main?.close();
    }

    const socket = io(`${baseURL.replace(/^http/, 'ws')}/${namespace ?? ''}`, {
      path: '/socket.io',
      transports: ['websocket'],
      withCredentials: true,
    });
    if (namespace) {
      set({ [namespace]: socket });
    } else {
      set({ main: socket });
    }
    return socket;
  },
}));

const tab =
  import.meta.env.NODE_ENV === 'test' ?
    ({} as TabElect)
  : new TabElect('main-page');

export const initMainSocket = () => {
  const { init } = useSocket.getState();
  const socket = init();

  socket.on('call', (call) => {
    const { workerId, phone } = call;
    if (tab.isLeader) {
      window.open(`/workers/${workerId ?? `new?phone=${phone}`}`);
    }
  });

  socket.on('connect_error', (e) => {
    // eslint-disable-next-line no-console
    console.error('connect_error', e);
  });

  socket.on('reports', (payload) => {
    const { type, ...data } = payload;
    useReportsStore.getState().setReport(type, data);
  });

  socket.on('receipts/status-update', (payload) => {
    const { status, entityId } = payload;
    queryClient.setQueryData(['receipts', entityId, 'status'], { status });
    receiptModel.queries.invalidateOne(entityId);
  });
};

export default useSocket;
