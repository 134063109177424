export const COLORS = {
  empty: { bg: '#f9bbd2', fg: '#c31f5c' },
  warning: { bg: '#fffdd7', fg: '#ffa049' },
  waiting: { bg: '#d8efff', fg: '#15499f' },
  danger: { bg: '#ffe0b7', fg: '#f58020' },
  active: { bg: '#dcecc8', fg: '#69a042' },
  done: { bg: '#b3e0dd', fg: '#0c4e42' },
  fail: { bg: '#fff5f5', fg: '#f04438' },
  grey: { bg: '#f8f8f8', fg: '#7e7e7e' },
  greyer: { bg: '#ddd', fg: '#555' },
} as const satisfies Record<string, { bg: string; fg: string }>;

export const colors = {
  green: '#87d068',
  orange: 'rgb(255 150 37)',
  red: '#e42929',
  gray: '#c4c4c4',
} as const satisfies Record<string, string>;
