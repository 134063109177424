import Cookies from 'js-cookie';

import { TOKEN_COOKIE } from './constants';
import type { LoginRes } from './types';

const cookieDomain = () =>
  document.location.hostname.includes('localhost') ?
    'localhost'
  : import.meta.env.REACT_APP_DOMAIN;

export const getToken = () => Cookies.get(TOKEN_COOKIE);

export const onLogin = (data: LoginRes) => {
  const expires = new Date(data.token.expiresAt);
  Cookies.set(TOKEN_COOKIE, data.token.code, {
    expires,
    domain: cookieDomain(),
  });
  Cookies.set(TOKEN_COOKIE, data.token.code, { expires });
};

export const onLogout = (withRedirect = true) => {
  Cookies.remove(TOKEN_COOKIE, {
    domain: cookieDomain(),
  });
  Cookies.remove(TOKEN_COOKIE);
  if (withRedirect) document.location.pathname = '/';
};
