import { message } from 'antd';

import api from 'shared/api';

const resetPassword = async (email: string) => {
  try {
    await api.patch('/password-reset', { email });
    void message.success('Пароль успешно сброшен, проверьте вашу почту');
    return true;
  } catch (err) {
    await message.error('Произошла непредвиденная ошибка');
    return false;
  }
};

export default resetPassword;
