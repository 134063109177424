import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { ApiError } from 'shared/api';
import api from 'shared/api';

import type { LoginReq, LoginRes } from './types';
import { onLogin, onLogout } from './controller';

export const useLogin = (
  options?: Omit<
    UseMutationOptions<void, ApiError, LoginReq>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: LoginReq) => {
      const { data } = await api.post<LoginRes>(`/auth`, body);
      onLogin(data);
      return queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
    ...options,
  });
};

export const useLogout = (
  options?: Omit<
    UseMutationOptions<void, never, void>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      onLogout();
      return queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
    ...options,
  });
};
