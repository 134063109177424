import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import type { ApiError } from 'shared/api';
import api from 'shared/api';
import { queryClient } from 'shared/query';
import type { ObjectId } from 'shared/types';

import type { ReceiptGenerationStatus } from './types';

export const useGenerationStatus = <Data = { status: ReceiptGenerationStatus }>(
  entityId?: string,
  options?: Omit<
    UseQueryOptions<{ status: ReceiptGenerationStatus }, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: ['receipts', entityId, 'status'],
    queryFn: async ({ signal }) =>
      api
        .get<{ status: ReceiptGenerationStatus }>(
          `/receipts/${entityId}/status`,
          {
            signal,
          },
        )
        .then(({ data }) => data),
    ...options,
    enabled: !!entityId && options?.enabled !== false,
  });

export const invalidateLists = () => {
  void queryClient.invalidateQueries({
    queryKey: ['transactions', 'list'],
  });
  void queryClient.invalidateQueries({ queryKey: ['jobs', 'list'] });
};

export const invalidateOne = (id: ObjectId) => {
  void queryClient.invalidateQueries({
    queryKey: ['transactions', id],
  });
  void queryClient.invalidateQueries({ queryKey: ['jobs', id] });
};

export const invalidate = (id?: ObjectId) => {
  invalidateLists();
  if (id) {
    invalidateOne(id);
  }
};
