import * as queries from './queries';
import * as mutations from './mutations';
import * as acl from './acl';
import { useCurrent } from './store';
import * as controller from './controller';

const operatorModel = {
  queries,
  mutations,
  acl,
  useCurrent,
  controller,
};

export * as acl from './acl';

export * from './constants';
export * from './types';
export * from './ui';

export default operatorModel;
