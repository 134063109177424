import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { ApiError } from 'shared/api';
import api from 'shared/api';
import type { ObjectId } from 'shared/types';

import type { ReceiptCreateBody } from './types';
import { invalidate } from './queries';

export const useCreate = (
  options?: Omit<
    UseMutationOptions<void, ApiError, [ObjectId, ReceiptCreateBody]>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  return useMutation({
    mutationFn: async ([id, body]) => {
      await api.post<void>(`/receipts/jobs/create/${id}`, body);
      invalidate(id);
    },
    ...options,
  });
};

export const useReplace = (
  options?: Omit<
    UseMutationOptions<void, ApiError, ObjectId>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  return useMutation({
    mutationFn: async (id) => {
      await api.delete<void>(`/receipts/${id}/delete`);
      invalidate(id);
    },
    ...options,
  });
};

export const useApprove = (
  options?: Omit<
    UseMutationOptions<boolean, ApiError, ObjectId>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await api.post<boolean>(
        `/transactions/${id}/receipt/approve`,
      );
      invalidate(id);
      return data;
    },
    ...options,
  });
};

export const useRefuse = (
  options?: Omit<
    UseMutationOptions<boolean, ApiError, [ObjectId, string]>,
    'mutationKey' | 'mutationFn'
  >,
) => {
  return useMutation({
    mutationFn: async ([id, reason]) => {
      const { data } = await api.post<boolean>(
        `/transactions/${id}/receipt/refuse`,
        {
          reason,
        },
      );
      invalidate(id);
      return data;
    },
    ...options,
  });
};
