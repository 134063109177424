import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import App from 'app';

const GLITCHTIP_DSN = import.meta.env.REACT_APP_GLITCHTIP_DSN;

if (GLITCHTIP_DSN) {
  Sentry.init({
    dsn: GLITCHTIP_DSN,
    ignoreErrors: [
      'IDB database',
      'AxiosError',
      'Error: Unable to preload',
      'TypeError: Failed to fetch dynamically imported module',
      '<unknown>',
    ],
    beforeBreadcrumb: (breadcrumb) => {
      if ((breadcrumb.data?.url as string)?.includes('mc.yandex.ru')) {
        return null;
      }
      return breadcrumb;
    },
  });
}

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

root.render(<App />);
